<template>
  <el-upload
    class="uploadStyle"
    action="#"
    accept="image/*"
    :multiple="true"
    :show-file-list="false"
    :auto-upload="false"
    :on-change="onChange"
  >
    <el-avatar v-if="fileInfo.filePath" :src="fileInfo.filePath"></el-avatar>
    <el-avatar v-else icon="el-icon-user-solid"></el-avatar>
  </el-upload>
</template>

<script>
  export default {
    name: 'uploadAvatar',
    props: {
      cur: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        fileInfo: this.cur
      };
    },
    watch: {
      cur(val) {
        this.fileInfo = val;
      }
    },
    methods: {
      onChange(file) {
        const form = new FormData();
        form.append('file', file.raw);
        this.fileInfo = {
          form,
          filePath: window.URL.createObjectURL(file.raw)
        };
        this.$emit('input', this.fileInfo);
        this.$message.success('上传成功！');
      },
      deleteFile() {
        this.fileInfo = {};
        this.$emit('input', {});
      }
    }
  };
</script>

<style lang="scss" scoped></style>
