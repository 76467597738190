<template>
  <dog-dialog
    ref="dogDialog"
    width="400px"
    title="编辑用户 "
    @loading-confirm="confirm"
    @closed="closed"
  >
    <dog-form
      ref="dogForm"
      :form-data="formData"
      :form-options="formOptions"
    ></dog-form>
  </dog-dialog>
</template>

<script>
  import userService from '@/api/service/user.js';
  import fileService from '@/api/service/file';
  import uploadAvatar from './uploadAvatar.vue';

  export default {
    name: 'editUserDialog',
    data() {
      return {
        uploaded: false,
        formData: {},
        formOptions: [
          {
            type: 'input',
            formItem: {
              label: '真实姓名',
              prop: 'realName',
              rules: []
            }
          },
          {
            type: 'select',
            formItem: {
              label: '性别',
              prop: 'sex',
              rules: []
            },
            attrs: {
              service: this.$enumService('sex'),
              clearable: false
            }
          },
          {
            type: 'custom',
            formItem: {
              label: '头像',
              prop: 'avatar',
              rules: []
            },
            component: uploadAvatar,
            listeners: {
              input: () => {
                this.uploaded = true;
              }
            }
          },
          {
            type: 'input',
            formItem: {
              label: '个人简介',
              prop: 'remark',
              rules: []
            },
            attrs: {
              type: 'textarea',
              rows: 3
            }
          }
        ]
      };
    },
    methods: {
      openFrame(id) {
        this.$refs.dogDialog.open();
        userService.getUserDetail({ id }).then((res) => {
          this.formData = {
            ...res,
            avatar: {
              filePath: res.avatar
            }
          };
        });
      },
      confirm(done) {
        this.$refs.dogForm
          .validate()
          .then(() => {
            return this.uploaded
              ? fileService.upload(this.formData.avatar.form)
              : Promise.resolve();
          })
          .then((res) => {
            const formAvatar = this.formData.avatar
              ? this.formData.avatar.filePath
              : '';
            const params = {
              id: this.formData.id,
              realName: this.formData.realName,
              sex: this.formData.sex,
              avatar: res ? res.filePath : formAvatar,
              remark: this.formData.remark
            };
            return userService.editUser(params);
          })
          .then(() => {
            done();
            this.$emit('confirm');
            this.close();
            if (this.formData.id === this.$store.getters.userInfo.id) {
              this.$store.dispatch('setUserInfo');
            }
          })
          .catch(done);
      },
      close() {
        this.$refs.dogDialog.close();
      },
      closed() {
        this.formData = {};
        this.uploaded = false;
      }
    }
  };
</script>

<style lang="scss" scoped></style>
